<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <v-tabs background-color="white" color="blue" left>
          <v-tab class="nocaps">Account Daten</v-tab>
          <v-tab class="nocaps">Passwort ändern</v-tab>
          <v-tab-item>
            <br />
            <span v-if="updateErfolg">
              <h3 class="green--text">Wir haben Ihre Daten geändert</h3>
            </span>

            <v-form ref="updateForm" @submit.prevent="submitUserUpdate">
              <h3>Hier können Sie Ihre Kontaktdaten bearbeiten</h3>
              <span class="caption">
                Wenn Sie Ihre E-Mail ändern wollen, schicken Sie uns bitte eine
                E-Mail mit Ihrer User-ID: {{ userdata.user_id }} an:
                info@waizmanntabelle.de .
              </span>
              <v-layout class="mt-4">
                <v-flex xs12>
                  <v-text-field
                    v-model="userdata.user_name"
                    background-color="white"
                    type="text"
                    label="Name"
                    :rules="[checkTextFeld('user_name')]"
                  />
                </v-flex>
              </v-layout>
              <v-layout class="mt-4">
                <v-flex xs12>
                  <v-text-field
                    v-model="userdata.user_url"
                    background-color="white"
                    type="text"
                    label="Ihre Webseite"
                  />
                </v-flex>
              </v-layout>

              <v-layout class="mt-4">
                <v-flex xs8>
                  <v-text-field
                    v-model="userdata.user_strasse"
                    background-color="white"
                    type="text"
                    label="Ihre Strasse"
                    :rules="[checkTextFeld('user_strasse')]"
                  />
                </v-flex>
                <v-flex xs4 ml-4>
                  <v-text-field
                    v-model="userdata.user_hsnr"
                    background-color="white"
                    type="text"
                    label="Hausnummer"
                  />
                </v-flex>
              </v-layout>

              <v-layout class="mt-4">
                <v-flex xs4>
                  <v-text-field
                    v-model="userdata.user_plz"
                    background-color="white"
                    type="text"
                    label="PLZ"
                    :rules="[checkTextFeld('user_plz')]"
                  />
                </v-flex>
                <v-flex xs8 ml-2>
                  <v-text-field
                    v-model="userdata.user_ort"
                    background-color="white"
                    type="text"
                    label="Ort"
                    :rules="[checkTextFeld('user_ort')]"
                  />
                </v-flex>
              </v-layout>

              <v-layout class="mt-2" justify="start">
                <v-flex xs12>
                  <v-btn
                    type="submit"
                    tile
                    color="blue"
                    class="nocaps"
                    style="color: white"
                  >
                    Jetzt Änderungen speichern
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-layout v-if="error_step2" class="mt-2">
                <v-flex xs1></v-flex>
                <v-flex xs11>
                  <v-alert
                    border="top"
                    colored-border
                    type="error"
                    elevation="2"
                    dismissible
                  >
                    <h3 class="subtitle red--text">
                      Achtung bei der Übertragung kam es zu einem Fehler
                    </h3>
                    {{ error_step2_text }}
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-form>
          </v-tab-item>

          <v-tab-item>
            <br />
            <span v-if="updatePwdErfolg">
              <h3 class="green--text">Wir haben Ihr Passwort geändert</h3>
            </span>

            <v-form
              ref="updatePassForm"
              v-if="!updatePwdErfolg"
              @submit.prevent="submitUserPwdUpdate"
            >
              <h3 class="title">Hier können Sie Ihr Passwort ändern</h3>
              <span class="caption">
                Wenn Sie Ihre E-Mail ändern wollen, schicken Sie uns bitte eine
                E-Mail mit Ihrer User-ID: {{ userdata.user_id }} an:
                info@waizmanntabelle.de .
              </span>

              <v-layout class="mt-4">
                <v-flex xs12>
                  <v-text-field
                    v-model="userdata.user_pass_neu1"
                    background-color="white"
                    :append-icon="showPwd1 ? 'visibility' : 'visibility_off'"
                    :rules="[checkTextFeld('user_pass_neu1')]"
                    :type="showPwd1 ? 'password' : 'text'"
                    label="Ihr neues Passwort"
                    autocomplete="on"
                    @click:append="showPwd1 = !showPwd1"
                  />
                </v-flex>
              </v-layout>

              <v-layout class="mt-4">
                <v-flex xs12>
                  <v-text-field
                    v-model="userdata.user_pass_neu2"
                    background-color="white"
                    :append-icon="showPwd2 ? 'visibility' : 'visibility_off'"
                    :rules="[checkTextFeld('user_pass_neu2')]"
                    :type="showPwd2 ? 'password' : 'text'"
                    label="Wiederholen Sie Ihr neues Passwort"
                    autocomplete="on"
                    @click:append="showPwd2 = !showPwd2"
                  />
                </v-flex>
              </v-layout>

              <v-layout class="mt-2" justify="start">
                <v-flex xs12>
                  <v-btn
                    type="submit"
                    tile
                    color="blue"
                    class="nocaps"
                    style="color: white"
                  >
                    Jetzt neues Passwort speichern
                  </v-btn>
                </v-flex>
              </v-layout>

              <v-layout v-if="error_step2" class="mt-2">
                <v-flex xs12>
                  <v-alert
                    border="top"
                    colored-border
                    type="error"
                    elevation="2"
                    dismissible
                  >
                    <h3 class="subtitle red--text">
                      Achtung bei der Übertragung kam es zu einem Fehler
                    </h3>
                    {{ error_step2_text }}
                  </v-alert>
                </v-flex>
              </v-layout>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "update-user",
  components: {},

  data: function () {
    return {
      showPwd1: false,
      showPwd2: false,
      updateSubmitted: false,
      error_step2: false,
      error_step2_text: false,
      updateErfolg: false,
      updatePwdErfolg: false,
      userdata: {
        user_name: "",
        user_email: "",
        user_strasse: "",
        user_hsnr: "",
        user_plz: "",
        user_ort: "",
        user_url: "",
        user_pass_neu1: "",
        user_pass_neu2: "",
      },
    };
  },
  created() {
    this.checkLogin();
  },

  methods: {
    checkLogin() {
      this.$log.debug("check app ob admin da");
      this.$store.dispatch("apiUserGet", "checklogin").then((resp) => {
        this.$log.debug("checklogin app", resp);
        if (resp.data.user) {
          this.$store.commit("user", resp.data.user);
          this.userdata = resp.data.user;
          this.userdata.user_pass_neu1 = "";
          this.userdata.user_pass_neu2 = "";
        }
      });
    },
    submitUserUpdate() {
      if (!this.checkForm()) {
        this.$log.debug("!checkform");
        return true;
      }
      this.$store.commit("start_loading_full");
      this.$log.debug("submit");
      this.error_step2_text = "";
      this.error_step2 = false;
      this.userdata.action = "updateuser";
      this.$store
        .dispatch("apiUserPost", this.userdata)
        .then((resp) => {
          if (resp.data.error_step2 == true) {
            this.$store.dispatch("insertError", 19);
            this.error_step2_text = resp.data.error_step2_text;
            this.error_step2 = resp.data.error_step2;
          } else {
            this.$store.commit("stop_loading_full");
            if (resp.data.erfolg) {
              this.updateErfolg = true;
              this.userdata.user_pass_neu1 = "";
              this.userdata.user_pass_neu2 = "";
              this.userdata = resp.data.user;
            } else {
              this.error_step1_text =
                "Fehler bitte versuchen Sie es noch einmal.";
              this.error_step1 = true;
              this.$store.dispatch("insertError", 20);
            }
          }
        })
        .catch((err) => {
          this.$log.error(err);
          // this.errors.push(err);
        });
    },
    checkFormPass() {
      let checkPassed = true;
      if (this.userdata.user_pass_neu1 != this.userdata.user_pass_neu2) {
        this.error_step2 = true;
        this.error_step2_text = "Ihre beiden Passwörter stimmen nicht überein.";
        this.$store.dispatch("insertError", 17);
        return false;
      } else if (
        !this.userdata.user_pass_neu1 ||
        !this.userdata.user_pass_neu2
      ) {
        this.$store.dispatch("insertError", 18);
        this.error_step2 = true;
        this.error_step2_text =
          "Bitte geben Sie Ihre Passwort an (und auch die Passwort-Wiederholung).";
        return false;
      } else {
        return checkPassed;
      }
    },
    checkForm() {
      let checkPassed = true;
      return checkPassed;
    },
    submitUserPwdUpdate() {
      if (!this.checkFormPass()) {
        this.$log.debug("!checkform pass");
        return false;
      }
      this.$store.commit("start_loading_full");
      this.$log.debug("submit");
      this.error_step2_text = "";
      this.error_step2 = false;
      this.userdata.action = "updateuserpasswort";
      this.$store
        .dispatch("apiUserPost", this.userdata)
        .then((resp) => {
          if (resp.data.error_step2 == true) {
            this.error_step2_text = resp.data.error_step2_text;
            this.error_step2 = resp.data.error_step2;
            this.$store.dispatch("insertError", 21);
          } else {
            this.$store.commit("stop_loading_full");
            if (resp.data.erfolg) {
              this.updatePwdErfolg = true;
              this.$router.push("/logout");
            } else {
              this.error_step1_text =
                "Fehler bitte versuchen Sie es noch einmal.";
              this.error_step1 = true;
              // this.$store.dispatch("insertError", 22);
            }
          }
        })
        .catch((err) => {
          this.$log.error(err);
          // this.errors.push(err);
        });
    },
    checkTextFeld(feld) {
      var showError = false;
      var TextError = "";

      if (feld == "user_name" && this.userdata.user_name == "") {
        TextError = "Bitte geben Sie Ihren Namen an. ";
        showError = true;
      }

      if (feld == "user_strasse" && this.userdata.user_strasse == "") {
        TextError = "Bitte geben Sie Ihren Namen an. ";
        showError = true;
      }

      if (feld == "user_plz" && this.userdata.user_plz == "") {
        TextError = "Bitte geben Sie Ihre PLZ an. ";
        showError = true;
      }

      if (feld == "user_ort" && this.userdata.user_ort == "") {
        TextError = "Bitte geben Sie Ihre Ort an. ";
        showError = true;
      }

      if (feld == "user_pass_neu1" && this.userdata.user_pass_neu1 == "") {
        TextError = "Bitte geben Sie Ihr neues Passwort  an. ";
        showError = true;
      }

      if (feld == "user_pass_neu2" && this.userdata.user_pass_neu2 == "") {
        TextError = "Bitte wiederholen Sie Ihr neues Passwort. ";
        showError = true;
      }

      if (showError == true) {
        return TextError;
      } else {
        return true;
      }
    },
  },
};
</script> 
